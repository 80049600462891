import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

const FooterNav = ({ className, ...props }) => {
  const classes = classNames("footer-nav", className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        <a href="https://www.mydurbar.com/contactus">
          <p>Contact Us</p>
        </a>

        <a href="https://mydurbar.com">
          <p>Explore our Home Page (BETA)</p>
        </a>
      </ul>
    </nav>
  );
};

export default FooterNav;
